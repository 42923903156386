<template>
    <div class="d-flex">
        <div class="my-calendar d-flex justify-start flex-column">
            <div
                v-on:click="value.is_show = false"
                class="
                    close-calendar
                    d-flex
                    align-center
                    justify-space-between
                    px-5
                "
                style="width: 100%"
            >
                <img :src="$store.state.icons.icons['RedCross']" />
                <div class="buttons">
                    <span class="mr-2" @click="value.is_show = false">
                        Apply
                    </span>
                    <span
                        @click="
                            (value.is_show = false), (value.filter_type = '')
                        "
                    >
                        Reset
                    </span>
                </div>
            </div>

            <div class="d-flex align-center justify-center">
                <datepicker
                    v-if="value.filter_type === 'year'"
                    minimum-view="year"
                    :inline="true"
                    v-model="displayValue"
                ></datepicker>

                <datepicker
                    v-else
                    minimum-view="month"
                    :initialView="'year'"
                    :inline="true"
                    v-model="displayValue"
                ></datepicker>
            </div>

            <div class="d-flex filter-select justify-start flex-column">
                <p class="heading-sf16 mb-2">Filter By</p>
                <div class="d-flex align-center">
                    <div
                        v-on:click="value.filter_type = 'month'"
                        class="filter-box d-flex align-center justify-center"
                        :class="{
                            'filter-selected': value.filter_type === 'month',
                        }"
                    >
                        Month
                    </div>

                    <div
                        v-on:click="value.filter_type = 'year'"
                        class="
                            filter-box
                            d-flex
                            align-center
                            justify-center
                            ml-auto
                        "
                        :class="{
                            'filter-selected': value.filter_type === 'year',
                        }"
                    >
                        Year
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Datepicker from "vuejs-datepicker";
export default {
    // data() {
    // 	return {
    // 		is_month: true,
    // 	};
    // },
    components: {
        Datepicker,
    },
    props: {
        value: Object,
    },

    watch: {
        displayValue: {
            deep: true,
            handler() {},
        },
    },

    mounted() {
        if (!this.value.filter_type) {
            this.value.filter_type = "year";
        }
    },

    computed: {
        displayValue: {
            get() {
                return moment(this.value.filter_date).format("YYYY-MM");
            },
            set(modifiedValue) {
                this.value.filter_date =
                    moment(modifiedValue).format("YYYY-MM");
            },
        },
    },
};
</script>

<style lang="scss">
    .my-calendar {
        background: #ffffff;
        box-shadow: 0 22px 54px 0 rgba(0, 0, 0, 0.2);
        border-radius: 8px !important;
        height: auto;
        width: 340px;
        z-index: 0;
        margin-bottom: -360px !important;

        .v-picker__title,
        .v-picker__title--landscape {
            display: none;
        }

        .v-card {
            box-shadow: 0px 0px 0px !important;
            border-radius: 8px !important;
        }
        .v-picker__body,
        .vdp-datepicker__calendar {
            margin: 0px 0px 0px 0px !important;
            width: 312px !important;
            border: none !important;
            // padding: 24px 0px 0px 0px;
        }

        .vdp-datepicker__calendar header {
            line-height: 60px !important;
        }

        .v-btn__content,
        .vdp-datepicker__calendar .cell {
            font-family: Inter-Regular !important;
            font-size: 14px !important;
            color: rgba(0, 0, 0, 0.87) !important;
            height: auto !important;
        }

        .v-btn:before {
            background: #ffffff !important;
            border: none;
            border-radius: 4px !important;
        }

        .v-btn--rounded {
            border-radius: 4px !important;
        }

        .v-btn--active,
        .vdp-datepicker__calendar .cell.selected {
            background: rgba(41, 116, 255, 0.05) !important;
            border: 1px solid #2974ff !important;
            border-radius: 4px !important;
        }

        .v-btn--disabled {
            .v-btn__content {
                color: #a3a3a3 !important;
            }
        }

        .v-date-picker-table > table > thead > tr > th {
            font-family: Inter-Regular;
            font-size: 14px;
            color: #55647b;
            text-align: center;
            padding: 0px 0px 16px 0px;
        }

        .v-date-picker-table--month td {
            height: 46px !important;
        }

        .v-btn {
            height: 36px !important;
            width: 36px !important;
        }

        .v-date-picker-header__value,
        .vdp-datepicker__calendar,
        .v-btn--icon {
            color: #414141 !important;
            font-family: Inter-SemiBold;
            font-size: 18px;
        }

        .v-date-picker-table {
            height: 200px !important;
        }

        .v-date-picker-table,
        .v-date-picker-header {
            padding: 0px;
            padding-bottom: 4px;
        }

        .v-date-picker-header__value button:hover {
            color: #414141 !important;
        }

        .filter-select {
            border-top: 1px solid #ebeef6;
            width: 100%;
            // height: 100%;
            padding: 16px;
        }

        .filter-box {
            background: #ffffff;
            border: 2px solid #dae6f7;
            border-radius: 4px;
            height: 48px;
            width: 150px;
            font-family: Inter-SemiBold;
            font-size: 16px;
            color: #212121;
            cursor: pointer;
        }

        .filter-selected {
            border: 2px solid #2974ff;
        }

        .filter-box:hover {
            border: 2px solid #2974ff !important;
        }
        .vdp-datepicker__calendar .cell:not(.blank):not(.disabled).year:hover,
        .vdp-datepicker__calendar .cell:not(.blank):not(.disabled).month:hover {
            border: 1px solid #2974ff !important;
        }
    }

    .close-calendar {
        background: #ffffff;
        // box-shadow: 0 18px 50px 0 rgba(0,0,0,0.20);
        border-radius: 8px;
        height: 46px;
        width: 46px;
        cursor: pointer;

        img {
            height: 16px;
            filter: brightness(0) saturate(100%) invert(17%) sepia(1%)
                saturate(2954%) hue-rotate(9deg) brightness(107%) contrast(80%);
        }

        .buttons {
            span {
                font-size: 15px;
                font-weight: 600;
                border-bottom: 1px solid;
                padding: 2px 0;
                &:hover {
                    color: #2974ff;
                }
            }
        }
    }
</style>
